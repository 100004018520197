import { defineCustomElements } from "@riverty/web-components/loader";
import { dynamicImport, loading } from "@/utils/common";
import { Client, TextResourceList } from "@/entities";
import { get, post, put } from "@/utils/httpRequest";
import { getTextResources } from "tests/entities";
import { ModalComponent } from "@/components";
import { Telemetry } from "@/utils/telemetry";
import { LanguageCode } from "@/enums";
import { defineComponent } from "vue";

interface CountryCodeEvent extends Event {
    detail: string;
}

export default defineComponent({
    name: "OnboardingView",
    components: {
        ModalComponent,
    },
    data: function () {
        return {
            elementUpdated: false,
            textResourceList: new TextResourceList(),
        };
    },
    mounted() {
        Telemetry.pageLoaded(`${this.$options.name}`);

        const intakeWidget = dynamicImport({
            url: `${process.env.VUE_APP_ONBOARDING_URL}/rivertyonboarding/build/riverty-onboarding-form.esm.js`,
            isModule: true,
        });
        intakeWidget.onerror = () => {
            Telemetry.customEvent(`${this.$options.name}`, "OnboardingWidgetError");
            window.location.href = "/settings";
        };
        intakeWidget.onload = async () => {
            await this.getClient();
            defineCustomElements(window);
            window.location.href = "#modal-content";
        };

        setTimeout(() => {
            const riverty = document.getElementsByTagName("riverty-onboarding")?.item(0);
            riverty?.addEventListener("countryCodeEvent", async (e: Event) => {
                const event = e as CountryCodeEvent;
                await put(`application/update-country/${event.detail}`);
            });
        }, 500);
    },
    methods: {
        async getClient(fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null) {
            loading();
            const client = new Client(await get("application/merchant-info"));
            this.textResourceList = (await this.getTextResources(client.language, fetchFromTest, httpsAgent)) as TextResourceList;

            client.completeProcessLinkText = this.getTranslation("ReturnToAfterOnboarding");

            const rivertyOnboarding = document.createElement("riverty-onboarding");
            rivertyOnboarding.setAttribute("language", client.language);
            rivertyOnboarding.setAttribute("client", client.toJson());
            document.querySelector(".page")?.prepend(rivertyOnboarding);
            loading();
        },
        handleGoOnboard() {
            window.location.href = "#";
        },
        async handleGoSettings() {
            Telemetry.pageRedirected(`${this.$options.name}`);
            await get("application/refresh-token");
            window.location.href = "/settings";
        },
        async getTextResources(languageCode: string, fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null): Promise<TextResourceList | getTextResources> {
            const sysnameList = ["PopupOnboardTitle", "PopupOnboardDescription", "PopupOnboard", "PopupSettingsTitle", "PopupSettingsDescription", "PopupSettings", "ReturnToAfterOnboarding"];

            const response = await post(
                "text-resources/list",
                {
                    languageCode,
                    sysnameList,
                },
                fetchFromTest,
                httpsAgent
            );

            if (!fetchFromTest) return response;

            return { obj: response, list: sysnameList };
        },
        getTranslation(sysName: string, replace: string[] = []): string {
            let value = this.textResourceList?.textResourceItems.find((x) => x.sysName == sysName)?.value;

            replace.forEach((item: string, index: number) => {
                value = value?.replace(`{${index}}`, item);
            });

            return value ?? "";
        },
    },
});
