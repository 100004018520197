import { defineCustomElements } from "@riverty/web-components/loader";
import { getTextResources } from "tests/entities";
import { TextResourceList } from "@/entities";
import { Telemetry } from "@/utils/telemetry";
import { post } from "@/utils/httpRequest";
import { loading } from "@/utils/common";
import { LanguageCode } from "@/enums";
import { defineComponent } from "vue";

export default defineComponent({
    name: "ErrorView",
    data: function () {
        return {
            language: LanguageCode[LanguageCode.EN],
            textResourceList: new TextResourceList(),
        };
    },
    mounted() {
        Telemetry.pageLoaded(`${this.$options.name}`);

        const urlParams = new URLSearchParams(window.location.search);
        const langParam = urlParams.get("lang");
        if (langParam) {
            this.language = langParam;
        }
        this.getData().then(() => defineCustomElements(window));
    },
    methods: {
        async getData(fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null) {
            loading();
            this.textResourceList = (await this.getTextResources(this.language, fetchFromTest, httpsAgent)) as TextResourceList;
            loading();
        },
        handleReturnTo() {
            Telemetry.pageRedirected(`${this.$options.name}`);
            window.location.href = "https://www.riverty.com/";
        },
        async getTextResources(languageCode: string, fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null): Promise<TextResourceList | getTextResources> {
            const sysnameList = ["TechnicalErrorTitle", "TechnicalErrorDescription", "ReturnTo"];

            const response = await post(
                "text-resources/list",
                {
                    languageCode,
                    sysnameList,
                },
                fetchFromTest,
                httpsAgent
            );

            if (!fetchFromTest) return response;

            return { obj: response, list: sysnameList };
        },
        getTranslation(sysName: string, replace: string[] = []): string {
            let value = this.textResourceList?.textResourceItems.find((x) => x.sysName == sysName)?.value;

            replace.forEach((item: string, index: number) => {
                value = value?.replace(`{${index}}`, item);
            });

            return value ?? "";
        },
    },
});
