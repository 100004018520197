import { CountryCode, LanguageCode } from "@/enums";

class MerchantSettings {
    languageCode = LanguageCode[LanguageCode.EN];
    redirectUrl = "https://www.riverty.com/";
    merchantSettingsApiKeyValues = new Array<MerchantSettingsApiKeyValue>();
}

class MerchantSettingsApiKeyValue {
    country = CountryCode[CountryCode.NL];
    apiKey = "";

    init(countryCode: CountryCode) {
        this.country = CountryCode[countryCode];
        this.apiKey = "";

        return this;
    }
}

export { MerchantSettings, MerchantSettingsApiKeyValue };
