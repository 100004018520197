import { SimpleSpanProcessor, Tracer, WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { ZipkinExporter } from "@opentelemetry/exporter-zipkin";
import version from "../../package.json";

export const Telemetry = {
    provider: null as WebTracerProvider | null,
    exporter: null as ZipkinExporter | null,
    tracer: null as Tracer | null,
    attributes: {} as Record<string, string>,

    init() {
        this.provider = new WebTracerProvider();
        this.exporter = new ZipkinExporter({
            url: "https://trace-api.newrelic.com/trace/v1",
            headers: {
                "X-License-Key": `${process.env.VUE_APP_NEW_RELIC_LICENSE_KEY}`,
                "Data-Format": "zipkin",
                "Data-Format-Version": "2",
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });

        this.tracer = this.provider.getTracer("hosted-checkout-frontend telemetry");
        this.provider.addSpanProcessor(new SimpleSpanProcessor(this.exporter));
        this.provider.register();
        this.attributes = { device: this.getDevice(), os: this.getOs(), hostUrl: this.getHost(), version: this.getVersion() };
    },

    getDevice() {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobile = userAgent.match(/mobile/i),
            isTablet = userAgent.match(/tablet/i);

        return `${isMobile ? "Mobile" : isTablet ? "Tablet" : "Desktop"}`;
    },

    getOs() {
        const userAgent = navigator.userAgent.toLowerCase();
        const isAndroid = userAgent.match(/android/i),
            isiPhone = userAgent.match(/iphone/i),
            isiPad = userAgent.match(/ipad/i),
            isWindows = userAgent.match(/windows/i);

        if (isAndroid) {
            return "Android";
        } else if (isiPhone || isiPad) {
            return "iOs";
        } else if (isWindows) {
            return "Windows";
        } else {
            return "Other";
        }
    },

    getHost() {
        return window.location.host;
    },

    getVersion() {
        return version.version;
    },

    createSpan(pageName: string, event: string, orderKey: string) {
        this.attributes = { ...this.attributes, orderKey };
        const span = this.tracer?.startSpan(pageName + event, {
            kind: 1,
            attributes: this.attributes,
        });

        span?.addEvent(pageName + event);
        span?.end();
    },

    pageOpened(pageName: string, orderKey = "") {
        this.createSpan(pageName, "PageOpened", orderKey);
    },

    pageLoaded(pageName: string, orderKey = "") {
        this.createSpan(pageName, "PageLoaded", orderKey);
    },

    pageRedirected(pageName: string, orderKey = "") {
        this.createSpan(pageName, "PageRedirected", orderKey);
    },

    pageClosed(pageName: string, orderKey = "") {
        this.createSpan(pageName, "PageClosed", orderKey);
    },

    customEvent(pageName: string, event: string, orderKey = "") {
        this.createSpan(pageName, event, orderKey);
    },
};
